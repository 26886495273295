import { Button, Input, Modal, Form } from 'antd';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as action from '../store/actions/Index'
import { Table, Popconfirm, Popover, Select } from 'antd';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import { Space, Switch } from 'antd';
import Loaidngcom from "./loadingcomponent"

import Meta5APIlist from '../store/services/Meta5Apilist'//FayersAPIlist
import MetaPostdesibalservices from "../store/services/Meta5postdesibaleservies"//fyersPostdesibalservices
import Meta5deletion from '../store/services/Meta5delete'//Apideletion
import Meta5activesatus from "../store/services/Meta5activestause" //Fyersactivestatus
import Meta5validation from "../store/services/Meta5validation"//Fayesrvalidation
import Apicrationmtafive from '../store/services/apicartionmetaservices'//apicration

const CreateMata5API = ({tradeStatus}) => {

    const [mtborker, setMtbroker] = useState([
        { brokierid: 1, brokername: "Mt4" },
        { brokierid: 2, brokername: "Mt5" }
    ])
    const { Option } = Select;
    const [meta5createopenmodel, setmeta5createopenmodel] = useState(false);//const [modal1Open, setModal1Open] = useState(false);
    const [meta5editopenmodel, setmeta5editopenmodel] = useState(false);// const [modal2Open, setModal2Open] = useState(false);

    const [saveapi, setSaveapi] = useState(false)

    const [appname, setAppname] = useState("")
    const [loginid, setloginid] = useState("")
    const [password, setpassword] = useState("")
    const [server, setserver] = useState("")
    const [brokerid, setbrokerid] = useState("")
    const [brokername, setbrokername] = useState("")

    const [metafiveactive, setMetafiveactive] = useState([])

    const authState = useSelector(state => state.authState)
    const apicreate = useSelector(state => state.apicreate);
    const userId = localStorage.getItem('userid')
    const dispatch = useDispatch();
    const uid = userId

    const [isedting, setIsediting] = useState(false)
    const [editingmeta5api, setEditingmeta5api] = useState(null)

    const [editMetaid, setEditMetaid] = useState("")
    const [editappname, seteditappname] = useState("")
    const [editloginid, seteditloginid] = useState("")
    const [editpassword, seteditpassword] = useState("")
    const [editserver, seteditserver] = useState("")
    const [editbrokerid, seteditbrokerid] = useState("")
    const [editbrokername, seteditbrokername] = useState("")

    const [apiactivedata, setApiactivedata] = useState([])
    const [activemetaapi, setactivemetaapi] = useState(false)
    const [activslider, setActiveclider] = useState()
    const [valiedform, setValiedform] = useState(true)

    useEffect(() => {
        let userid = userId
        Meta5activesatus.meta5active(userid).then((apires) => {
            <Loaidngcom />

            setApiactivedata(apires.data.data)
            setActiveclider(apires.data.data.status)
        })
            .catch((error) => {
                console.log("faild")
            })

    }, [])

    const UpdateSt = (e, cItem, field) => {

        const newState = metafiveactive.map(obj => {
            if (cItem.mtId == obj.mtId) {
                let newObj = { ...obj };
                if (e == true) {
                    newObj[field] = "ok"
                }
                else {
                    newObj[field] = "false"
                }
                return newObj;
            }
            return obj;
        });
        setMetafiveactive(newState);
        console.log(newState)

        if (e !== true) {
            let metafiveid = cItem.mtId
            const params = { metaid: metafiveid }

            MetaPostdesibalservices.metaPostdesibalservices(params).then((apiRes) => {
                <Loaidngcom />
                if (apiRes.data.isSuccess == true) {
                    toast.info("API Has been Inactive", {
                        position: "top-center"
                    })
                }

            })
                .catch((error) => {
                    console.log("faild")
                });
        } else {
            let appid = cItem.appId
            let metafiveID = cItem.mtId
            // the sotware url is notworkig for fayers
            window.open("https://api.fyers.in/api/v2/generate-authcode?client_id=" + appid + "&redirect_uri=http://software.asiabullalgo.in/fyerssuccessful&response_type=code&state=" + metafiveID)
            // window.open("https://api.fyers.in/api/v2/generate-authcode?client_id="+ appid +"&redirect_uri=http://httpshost:3000/fyerssuccessful&response_type=code&state="+metafiveID)

        }
    }

    const apicrating = () => {

        // dispatch(action.apicreatestart());
        //const params = { FyersId: 'MHIuYi5zb2Z0', UserId: uid, ClientID: clientid, AppId: appid, SecretId: secrectid, AppName: appname, Pin: pinid };
        //const params = { MtId: 'MHIuYi5zb2Z00',UserId:uid, AppName: appname, LoginId: loginid, Password: password, Server: server, Broker: brokername };

        const params = { MtId: "MHIuYi5zb2Z0", UserId: uid, AppName: appname, LoginId: loginid, Password: password, Server: server, Broker: "Meta" }
        console.log(params)
        Apicrationmtafive.apivrationmetafive(params).then((apiRes) => {

            <Loaidngcom />
            dispatch(action.apicreatestart(apiRes));
            console.log(apiRes)
            if (apiRes.data.isSuccess) {
                setAppname('')
                setloginid(' ')
                setpassword('')
                setserver(' ')
                setbrokername(' ')
                setmeta5createopenmodel(false)
                toast.info("API Has Been Created Sucessfully", {
                    position: "top-center"
                })
            }
        })

            .catch((error) => {

                console.log("faild")
            });
    }

    const openApicraationmodule = () => {
        setEditMetaid("")
        seteditappname("")
        seteditloginid("")
        seteditpassword("")
        seteditserver("")
        seteditbrokerid("")
        setmeta5createopenmodel(true)

    }

    const getmeta5list = (event) => {
        debugger
        const uid = userId
        const metaid = 0
        Meta5APIlist.meta5acetivelist(uid, metaid).then((apiRes) => {
            debugger    
            <Loaidngcom />
            setMetafiveactive(apiRes.data.data)
            setmeta5editopenmodel(true)
        })
            .catch((error) => {
                console.log("faild")

            });
    }

    const editshow = (record) => {

        setIsediting(true)
        setEditingmeta5api({ ...record })
        setEditMetaid(record.mtId)
        seteditappname(record.appName)
        const loginIdAsString = record.loginId.toString();
        seteditloginid(loginIdAsString);

        seteditpassword(record.password)
        seteditserver(record.server)
        seteditbrokername(record.broker)
    }

    const editrecoerd = () => {

        //const params = { fyersId: editfid, UserId: edituserid, ClientID: editclientid, AppId: editappid, SecretId: editsecid, AppName: editappname, Pin: editpin };
        const params = { MtId: editMetaid, UserId: uid, AppName: editappname, LoginId: editloginid, Password: editpassword, Server: editserver, Broker: editbrokername };
        console.log(params)
        Apicrationmtafive.apivrationmetafive(params).then((apiRes) => {
            <Loaidngcom />
            if (apiRes.data.isSuccess == true) {
                getmeta5list()
                setIsediting(false)
            }

            toast.info("Meta5 API Has Been updated Sucessfully", {
                position: "top-center"
            })
        })
            .catch((error) => {

                console.log("faild")

            });
    }

    const deleteapi = (record) => {

        let metafiveID = record.mtId
        let userid = userId
        const params = { MtId: metafiveID, UserId: userid };
        Meta5deletion.meta5deletion(params).then((apiRes) => {
            <Loaidngcom />
            if (apiRes.data.isSuccess == true) {
                getmeta5list()
            }

        })

            .catch((error) => {
                console.log("faild")
            });
    }
    //fetfayersapi
    const Meta5inactiveapi = (record) => {
        setactivemetaapi(!activemetaapi)
        if (activemetaapi == true) {
            let meta5id = record.metafiveID
            const params = { Fid: meta5id }

            MetaPostdesibalservices.metaPostdesibalservices(params).then((apiRes) => {

                if (apiRes.data.isSuccess == true) {
                    toast.info("Meta5 API Has been Inactive", {
                        position: "top-center"
                    })
                }

            })

                .catch((error) => {
                    console.log("faild")
                });
        } else {

            let appid = record.appId
            let metafiveID = record.metafiveID
            window.open("https://api.fyers.in/api/v2/generate-authcode?client_id=" + appid + "&redirect_uri=http://www.software.asiabullalgo.in/fyerssuccessful&response_type=code&state=" + metafiveID)
            // window.open("https://api.fyers.in/api/v2/generate-authcode?client_id=" + appid + "&redirect_uri=http://httpshost:3000/fyerssuccessful&response_type=code&state=" + metafiveID)

        }
    }

    // const validationclientid = (e) => {

    //     setClientid(e.target.value) // check 

    //     Meta5validation.meta5validation(clientid, 0, 0).then((apiRes) => {

    //         if (apiRes.data.message == "Record Found") {

    //             toast.dark("Client Id is allradey exits", {
    //                 position: "top-center"
    //             })
    //             setClientid("")

    //         } else {

    //         }

    //     })
    //         .catch((error) => {

    //             console.log("faild")
    //         });
    // }

    // const validationappid = (e) => {


    //     setAppid(e.target.value)
    //     Meta5validation.meta5validation(0, appid, 0).then((apiRes) => {

    //         if (apiRes.data.message == "Record Found") {

    //             toast.dark("App Id is allradey exits", {
    //                 position: "top-center"
    //             })
    //             setAppid("")
    //         }
    //         else {
    //             /* else Part  */
    //         }

    //     })
    //         .catch((error) => {

    //             console.log("faild")
    //         });
    // }

    // const validationsecreatid = (e) => {

    //     setSecretid(e.target.value)
    //     Meta5validation.meta5validation(0, 0, secrectid).then((apiRes) => {

    //         if (apiRes.data.message == "Record Found") {

    //             toast.dark("SecretId Id is allradey exits", {
    //                 position: "top-center"
    //             })
    //             setSecretid("")

    //         } else {

    //         }
    //     })
    //         .catch((error) => {

    //             console.log("faild")
    //         });
    // }

    const colums = [
        {
            title: "MetaId",
            dataIndex: "mtId",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.metafiveID - b.metafiveID,
                multiple: 1,
            }
        },
        // {
        //     title: "ClientId",
        //     dataIndex: "clientId",
        //     align: "center",

        // },
        {
            title: "AppName",
            dataIndex: "appName",
            align: "center"
        },
        {
            title: "Active/Inactive",
            dataIndex: "tradeStatus",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive"
                            checked={(record.tradeStatus == "ok") ? true : false}
                            // onChange={() => Meta5inactiveapi(record)}
                            onChange={(e) => { UpdateSt(e, record, "tradeStatus") }}
                        />
                    </>
                )
            }

        },
        {
            title: "Edit",
            dataIndex: "action",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Button type='primary' onClick={() => { editshow(record) }}>
                            Edit
                            <EditOutlined />
                        </Button>
                    </>
                )
            }

        },
        {
            title: "Delete",
            dataIndex: "action",
            align: "center",
            render: (_, record) => {
                return (
                    <>

                        <Popconfirm title={" Do you want to Delete this Record..?? \n (Note: if delete this record then Strategy Allocation record also deleted)"}
                            onConfirm={() => { deleteapi(record) }}
                        >
                            <Button danger>
                                Delete
                                <DeleteOutlined />
                            </Button>

                        </Popconfirm>
                    </>
                )
            }

        },



    ]

    const modifydata = metafiveactive.map(({ ...item }) => ({
        ...item,
        key: item.mtId,
    }))
    return (
        <>
        
            <Button type="primary" onClick={() => openApicraationmodule()}>
                Create  API 
            </Button>
            <Modal
                title="Create Meta 5 API" 
                style={{
                    top: 20,
                }}
                open={meta5createopenmodel}
                onOk={() => { apicrating() }}
                okType='default'
                okText="Save API"
                onCancel={() => setmeta5createopenmodel(false)}
                className='Createapi'

            >
                <Form>
                    <div className="modal-body">

                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>App Name :</label>
                            <Input type="text" value={appname} size="large"
                                id="appname"
                                onChange={(e) => setAppname(e.target.value)}

                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>login id :</label>
                            <Input type="text" size="large"
                                id="loginid" name="loginid" value={loginid}
                                onChange={(e) => setloginid(e.target.value)}
                            // onBlur={(e) => validationappid(e)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>password :</label>
                            <Input type="text" size="large"
                                id="Clientid" name="Clientid" value={password}
                                onChange={(e) => setpassword(e.target.value)}
                            // onBlur={(e) => validationclientid(e)}

                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Server:</label>
                            <Input type="text" size="large"
                                id="Secreacteid" name="Secreacteid" value={server}
                                onChange={(e) => setserver(e.target.value)}
                            // onBlur={(e) => validationsecreatid(e)}
                            />
                        </div>


                        <div className="mb-3">
                            <label htmlFor="broker" style={{ color: "Black", fontWeight: "bold" }}>Broker:</label>
                            <Select
                                id="broker"
                                name="broker"
                                value={brokername}
                                onChange={(value) => setbrokername(value)}
                                style={{ width: '100%' }}
                               
                            >
                                <Option value="MT5">MT5</Option>
                                <Option value="CMS">CMS</Option>
                            </Select>
                        </div>


                    </div>
                </Form>
            </Modal>

            <Button type="primary" onClick={() => getmeta5list()}>
                Edit API
            </Button>
            <Modal
                title="Edit Meta5 API"
                centered
                open={meta5editopenmodel}
                onCancel={() => setmeta5editopenmodel(false)}
                width={1000}
                className='Editapi'

            >
                <Table
                    columns={colums}
                    dataSource={modifydata}
                    bordered
                    scroll={{ x: true }}
                />
            </Modal>
            <Modal
                title="Editing"
                open={isedting}
                onOk={() => editrecoerd()}
                okText="Save Changes"
                onCancel={() => setIsediting(false)}
                width={400}
            >
                <div className='modal-body'>
                    <div className="mb-3">
                        <label hidden htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Metaid  :</label>
                        <Input disabled hidden value={editingmeta5api?.mtId} onChange={(e) => {
                            setEditingmeta5api(pre => {
                                return { ...pre, mtId: setEditMetaid(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>App Name :</label>
                        <Input name='editappname' id='editappname' value={editingmeta5api?.appName} onChange={(e) => {
                            setEditingmeta5api(pre => {
                                return { ...pre, appName: seteditappname(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>login id :</label>
                        <Input name='editappid' id='editappid' value={editingmeta5api?.loginId} onChange={(e) => {
                            setEditingmeta5api(pre => {
                                return { ...pre, loginId: setloginid(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>password:</label>
                        <Input
                            name='editclientid' id='editclientid'
                            value={editingmeta5api?.password}
                            onChange={(e) => {
                                setEditingmeta5api(pre => {

                                    return { ...pre, password: seteditpassword(e.target.value) }

                                })
                            }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>server :</label>
                        <Input name='editappname' id='editappname' value={editingmeta5api?.server} onChange={(e) => {
                            setEditingmeta5api(pre => {
                                return { ...pre, server: seteditserver(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Broker :</label>
                        <Select
                            name='editappname'
                            id='editappname'
                            disabled
                            value={editingmeta5api?.broker}
                            onChange={(e) => {
                                setEditingmeta5api(pre => {
                                    return { ...pre, broker: e.target.value };
                                });
                            }}
                            style={{ width: '100%', padding: '8px', borderRadius: '4px' }}
                        >
                            <Option value="MT5">MT5</Option>
                            <Option value="SMC">SMC</Option>
                        </Select>
                    </div>


                </div>
            </Modal>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};
export default CreateMata5API;