import React, { useEffect, useState } from "react";
import "./CustomCalendar.css"; // Import CSS
import Showprofitandlossforcalender from "../store/services/Showprofitandlossforcalender";

const CustomCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const [sampleData, Setsmapaldta] = useState([])
  const [firstDateOfMonth, setFirstDateOfMonth] = useState("");

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  

  useEffect(() => {
    const firstDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const formattedDate = formatDate(firstDate); // Ensure formatDate is correctly implemented
    setFirstDateOfMonth(formattedDate);
}, [currentDate]);

// ✅ API call should only be triggered when firstDateOfMonth is set
useEffect(() => {
    if (firstDateOfMonth) { // Ensure it's not null before making the API call
        fetchData(firstDateOfMonth);
    }
}, [firstDateOfMonth]); // Wait for firstDateOfMonth to be set before calling API

const fetchData = async (date) => {
    let tdate = firstDateOfMonth
    let userid = localStorage.getItem('userid');
    let fyersid = 2 

    Showprofitandlossforcalender.cutsomcalenderprofitlosss(tdate,fyersid,userid).then((apires)=>{
        debugger
        Setsmapaldta(apires.data.data)
        console.log(apires.data.data)


    }).catch((error)=>{
        debugger

        console.log("eeror found")
    })
};
  


//   const [sampleData, Setsmapaldta] = useState([

//     {
//       tId: 12,
//       tDate: "02-02-2025", // DD-MM-YYYY format
//       profitloss: 5400,
//       buy: 1,
//       sell: 1,
//     },
//     {
//       tId: 13,
//       tDate: "06-02-2025",
//       profitloss: 4800,
//       buy: 1,
//       sell: 1,
//     },
//   ]);



        useEffect(()=>{
        debugger
 
        let tdate = firstDateOfMonth
        let userid = localStorage.getItem('userid');
        let fyersid = 2 

        Showprofitandlossforcalender.cutsomcalenderprofitlosss(tdate,fyersid,userid).then((apires)=>{
            debugger
            Setsmapaldta(apires.data.data)
            console.log(apires.data.data)


        }).catch((error)=>{
            debugger

            console.log("eeror found")
        })



    },[])






  // Convert DD-MM-YYYY to YYYY-MM-DD for comparison
  const formatDateToISO = (dateStr) => {
    const [year,month,day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  };

  // Get first day and number of days in the month
  const firstDay = new Date(year, month, 1).getDay(); // 0 (Sun) - 6 (Sat)
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  // Create calendar grid
  const days = [];
  for (let i = 0; i < firstDay; i++) days.push(null); // Empty placeholders
  for (let i = 1; i <= daysInMonth; i++) days.push(i);

  // Get profit/loss for a specific date
  const getRecord = (day) => {
    const dateStr = `${day.toString().padStart(2, "0")}-${(month + 1)
      .toString()
      .padStart(2, "0")}-${year}`; // Convert to DD-MM-YYYY
    return sampleData.find((item) => item.tDate === dateStr) || null;
  };

  // Calculate total profit/loss for the entire month
  const getMonthProfitLoss = () => {
    return sampleData
      .filter(
        (item) => item.tDate.split("-")[1] === (month + 1).toString().padStart(2, "0") &&
          item.tDate.split("-")[2] === year.toString()
      )
      .reduce((acc, item) => acc + item.profitloss, 0);
  };

  const monthProfitLoss = getMonthProfitLoss(); // Get the total profit/loss for the month

  return (
    <div className="calendar-wrapper py-5" style={{marginTop:"80px"}}>
        {firstDateOfMonth}
      <div className="header">
        <button
          className="button"
          onClick={() =>
            setCurrentDate((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1))
          }
        >
          ◀ Prev
        </button>
        {currentDate.toLocaleString("default", { month: "long", year: "numeric" })}
        <button
          className="button"
          onClick={() =>
            setCurrentDate((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1))
          }
        >
          Next ▶
        </button>
      </div>

      {/* Display Month-wise Profit/Loss */}
      <div className="month-profit-loss">
        <h6>
          Total Profit/Loss :-{" "}
          <span
            className={
              monthProfitLoss > 0 ? "green" : monthProfitLoss < 0 ? "red" : "black"
            }
          >
            {monthProfitLoss > 0
              ? `+₹${monthProfitLoss}`
              : monthProfitLoss < 0
              ? `-₹${Math.abs(monthProfitLoss)}`
              : `₹0`}
          </span>
        </h6>
      </div>

      <div className="grid">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="day">
            {day}
          </div>
        ))}

        {days.map((day, index) => {
          if (!day) {
            return <div key={index} className="cell empty"></div>; // Empty cell for non-month days
          }

          const record = getRecord(day);
          let cellClass = "cell current-month"; // Default class
          if (record?.profitloss > 0) {
            cellClass += " profit"; // Apply profit class
          } else if (record?.profitloss < 0) {
            cellClass += " loss"; // Apply loss class
          }

          return (
            <div key={index} className={cellClass}>
              <span className="date">{day}</span>
              {record && (
                <div className="details">
                  <div className={`profit-loss ${record.profitloss > 0 ? "green" : "red"}`}>
                    {record.profitloss > 0
                      ? `+₹${record.profitloss}`
                      : `-₹${Math.abs(record.profitloss)}`}
                  </div>
                  <div className="buy-sell">
                    <span>Buy: {record.buy}</span>
                    <span>Sell: {record.sell}</span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomCalendar;
