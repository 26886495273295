import React, { useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { Redirect, useHistory } from 'react-router-dom';
import * as actions from '../store/actions/Index'
import authservice from "../store/services/authservice";
import { Form, Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Space } from 'antd';
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loaidngcom from "../component/loadingcomponent"


const SignIn = ({ setIsLoggedIn }) => {
    debugger
    const [username, userName] = useState("");
    const [userpass, setUserpass] = useState("");

  

    const formik = useFormik({

        initialValues: {
            inputemail: "",
            password: "",
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            inputemail: Yup.string()
                .email("Plese enter Valid Email address")
                .required("This is required"),
            password: Yup.string()
                .required("This is required")
        }),
        onSubmit: (values) => {
            console.log(values)

        }

    })

    const authState = useSelector(state => state.authState)
    const dispatch = useDispatch();
    const history = useHistory();


    if (authservice.isloading == "true") {
         
        <Loaidngcom />
    }

    const login = (event) => {
         

        const useremail = formik.values.inputemail
        const userpassword = formik.values.password
        dispatch(actions.loginStart());
        const params = { Email: useremail, Password: userpassword, UserFor: "Soft" };
        console.log(params)
        authservice.login(params).then((apires) => {
            debugger
            <Loaidngcom />
             
            let userid = apires.data.data.id
            let username = apires.data.data.profileName
            let auth = apires.data.data.authStatus
            let tstatus = apires.data.data.tradeStatus

            dispatch(actions.loginSuccess(0, userid, username, auth, tstatus));
             
            console.log(apires.data.isSuccess)
            console.log(params)
            if (userid > 0) {
                 
                localStorage.setItem('userid', userid)
                localStorage.setItem('username', username)
                localStorage.setItem('auth', auth)
                localStorage.setItem('tstatus', tstatus)
                localStorage.setItem('useremail', useremail)
               
                setIsLoggedIn(true);
                localStorage.setItem('setIsLoggedIn',setIsLoggedIn)
                let path = "/Dashboard";
                history.push(path);

            } else {
                toast.error("Check Email and password!", {
                    position: "top-center"
                })
            }




        })
            .catch((error) => {
                dispatch(actions.loginFail(error));
                console.log(error)
                toast.error("Check Network Conection", {
                    position: "top-center"
                })
            })



    }


    return (
        <div className="container authcontianer-main ">
            <div className="row authcontianer col-md-12">
                <div className="aut-sigin col-md-4">
                    <div>
                        <img src={require("../assets/images/Company_logo.png")} height={150} width={150} className="img-fluid" alt="logo" ></img>
                        <h1>Sign In</h1>
                        <h5>Please sign-in to your account and start the adventure</h5>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="col-md-12">
                                <div>
                                    <label htmlFor="inputEmail4" className="form-label">Email</label>
                                </div>
                                <div className="col-md-12">
                                    {/* <input type="email" className="form-control mb-0" placeholder="abc@exapmpal.com"
                                        id="inputemail" name="inputemail"
                                        onChange={formik.handleChange}
                                        value={formik.values.inputemail}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.inputemail && formik.errors.inputemail && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.inputemail}</p>} */}
                                    <Input size="large" placeholder="input E-mail"
                                        prefix={<MailOutlined />}
                                        id="inputemail" name="inputemail"
                                        onChange={formik.handleChange}
                                        value={formik.values.inputemail}
                                        onBlur={formik.handleBlur}

                                    />
                                    {formik.touched.inputemail && formik.errors.inputemail && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.inputemail}</p>}
                                    <div>
                                        <div className="col-md-12">
                                            <label htmlFor="inputPassword4" className="form-label" >Password</label>
                                        </div>
                                        <div className="col-md-12">
                                            {/* <input type="password" className="form-control" placeholder="********"
                                                id="password" name="password"
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                onBlur={formik.handleBlur}
                                            /> */}
                                            {/* {formik.touched.password && formik.errors.password && <p style={{ color: "red", fontSize: "13px" }}>{formik.errors.password}</p>} */}
                                            <Input.Password
                                                size="large"
                                                placeholder="input password"
                                                prefix={<LockOutlined />}
                                                id="password" name="password"
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                                onBlur={formik.handleBlur} />
                                            {formik.touched.password && formik.errors.password && <p style={{ color: "red", fontSize: "13px" }}>{formik.errors.password}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-check mt-3 "  >
                                <input className="form-check-input mb-3" type="checkbox" id="gridCheck" />
                                <label className="form-check-label " htmlFor="gridCheck">Remeber Me</label>
                                <div className="forgot-passs">
                                    <a href="/Frogotpassword">Forgot Password?</a>
                                </div>
                            </div>
                            <div>
                            </div>
                            <div className="btnsinin d-grid gap-5 mt-3">
                                {/* <a href="/Dashbord">Sign in</a> */}
                                <button type="button" disabled={!formik.isValid} className="btn btn-primary" onClick={login}>Sign in</button>
                            </div>
                            <div className="new-pplatform mt-3">
                                <p>New on our platform<a href="/Signup">?Create An Account</a></p>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="signright col-md-8">
                    <img src={require("../assets/images/Loginimg.jpg")} className="img-fluid" alt="Vecteezy.com"  ></img>
                    <a>Vecteezy.com</a>
                </div>
            </div>


            <ToastContainer
                position="top-right"
                autoClose={500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"

            />
        </div>

    )

}

export default SignIn