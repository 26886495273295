import Button from '@mui/material/Button';
import { MdSpaceDashboard } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { ImCart } from "react-icons/im";
import { RiMessage2Fill } from "react-icons/ri";
import { RiNotification4Fill } from "react-icons/ri";
import { GoTools } from "react-icons/go"
import { Link } from 'react-router-dom'
import { FaRobot } from "react-icons/fa"
import { IoHelpSharp } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
const Sidebar = () => {

   const history = useHistory();
   let logiid = localStorage.getItem('userid');

   if (logiid === null) {

       let path = "/";
       history.push(path);
       
   }
    
debugger
    const [activetab, setActivetab] = useState(null); // Set initial state to null
    const [isTogglesubmenu, setisTogglesubmenu] = useState(false);
    let Auth = localStorage.getItem('auth')
    const isopenSubmenu = (index) => {
        debugger
        if (activetab === index) {
            setisTogglesubmenu(!isTogglesubmenu); // Toggle if the same tab is clicked
        } else {
            setActivetab(index);
            setisTogglesubmenu(true); // Open submenu for a new tab
        }
    };

    return (

        <>
            <div className="sidebar">
                <ul>
                    {Auth}
                    <li>
                        <Button className={`w-100 $ ${activetab === 0 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(0)}>
                            <span className='icon'><MdSpaceDashboard /></span>
                           <Link to="/Dashboard"> Dashboard</Link>
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                    </li>
                    <li>
                        <Button className={`w-100 $ ${activetab === 1 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(1)}>
                            <span className='icon'><ImCart /></span>
                            MemberShip plan
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                        <div className={`submenuwarper ${activetab === 1 && isTogglesubmenu ? 'colapse' : 'colapsed'}`}>
                            <ul className='submenu'>
                                <li><Link to="/MemberShipplan">Buy Plan</Link></li>
                                <li><Link to="/MemberShipplan">Buy statargey</Link></li>
                            </ul>
                        </div>

                    </li>
                    <li>
                        <Button className={`w-100 $ ${activetab === 2 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(2)}>
                            <span className='icon'><FaRobot /></span>
                            Active Bot
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                        <div className={`submenuwarper ${activetab === 2 && isTogglesubmenu ? 'colapse' : 'colapsed'}`}>
                            <ul className='submenu'>
                                <li><Link to="/Apiactivaction">API Creation</Link></li>
                                <li><Link to="/FyersStargeyallocation">Fyers Strategy</Link></li>
                                <li><Link to="/Mt5Stargeyallocation">Mt5 Strategy</Link></li>
                            </ul>
                        </div>

                    </li>
                    <li>
                        <Button className={`w-100 $ ${activetab === 3 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(3)}>
                            <span className='icon'><GoTools /></span>
                            Tools
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                        <div className={`submenuwarper ${activetab === 3 && isTogglesubmenu ? 'colapse' : 'colapsed'}`}>
                            <ul className='submenu'>
                                <li><Link to="/Papertrading">PaperTrading</Link></li>
                                <li><Link to="/Mangment">Management</Link></li>
                                <li><Link to="/Trade"> Trade by soft</Link></li>
                                <li><Link to="/TelegramAlerts">Telegram</Link></li>
                                <li><Link to="/DashboardPL">Profit & loss</Link></li>
                                <li><Link to="/Tradecalender">Trade Calneder</Link></li>
                            </ul>
                        </div>

                    </li>
                    <li>
                        <Button className={`w-100 $ ${activetab === 4 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(4)}>
                            <span className='icon'><RiMessage2Fill /></span>
                            Massages
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                        <div className={`submenuwarper ${activetab === 4 && isTogglesubmenu ? 'colapse' : 'colapsed'}`}>
                            <ul className='submenu'>
                                <li><Link>Show Massages</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Button className={`w-100 $ ${activetab === 5 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(5)}>
                            <span className='icon'><RiNotification4Fill /></span>
                            Notifaction
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                        <div className={`submenuwarper ${activetab === 5 && isTogglesubmenu ? 'colapse' : 'colapsed'}`}>
                            <ul className='submenu'>
                                <li><Link>Show Notifaction</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Button className={`w-100 $ ${activetab === 6 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(6)}>
                            <span className='icon'><IoHelpSharp /></span>
                            Help
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>
                        <div className={`submenuwarper ${activetab === 6 && isTogglesubmenu ? 'colapse' : 'colapsed'}`} >
                            <ul className='submenu'>
                                <li><Link to="/Resorces">Resorces</Link></li>
                                <li><Link to="/Telegramchanel">Telegram</Link></li>
                                <li><Link to="/TelegramGroup">Whatsappt</Link></li>
                                <li><Link to="/Whatsapppage">Videos</Link></li>
                                <li><Link to="/TermsAndConditions">Terms & Conditions</Link></li>
                                <li><Link to="/CancellationRefund">Cancellation & Reafund</Link></li>
                                <li><Link to="/PrivacyPolicy">PrivacyPolicy</Link></li>
                                <li><Link to="/ContactUs">ContactUs</Link></li>
                            </ul>
                        </div>

                    </li>
                    <li>
                        <Button className={`w-100 ${activetab === 7 && isTogglesubmenu ? 'active' : ''}`} onClick={() => isopenSubmenu(7)} hidden={Auth != "Admin"}>
                            <span className='icon'><RiAdminFill /></span>
                            Admin Panel
                            <span className='arrow'><IoIosArrowForward /></span>
                        </Button>

                        <div className={`submenuwarper ${activetab === 7 && isTogglesubmenu ? 'colapse' : 'colapsed'}`} hidden={Auth != "Admin"}>
                            <ul className='submenu'>
                                <li><Link to="/Clientlist">Client List</Link></li>
                                <li><Link to="/Changeauth">Change Auth</Link></li>
                                <li><Link to="/UserInformation">User Information</Link></li>
                                <li><Link to="/Tradingviewlist">Exit Trade</Link></li>
                                <li><Link to="/Convertdatas">Convert Data</Link></li>
                                <li><Link to="/Profitandlosspostdata">ProfitAndloss From </Link></li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <br />
                <div className='logoutwraper'>
                    <div className='logoutbox'>
                        <Button variant="contained"><IoMdLogOut />logOut</Button>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Sidebar