import { Button, Input, Modal,Form } from 'antd';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as action from '../store/actions/Index'
import { Table, Popconfirm, Popover } from 'antd';


import _ from 'lodash';

import { ToastContainer, toast } from 'react-toastify';
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import { Space, Switch } from 'antd';

import apicration from '../store/services/apicartionservices'
import fyersPostdesibalservices from "../store/services/fyerspostdesabale"
import Apideletion from '../store/services/fayerdelete'
import FayersAPIlist from '../store/services/fayersapilist'
import Fyersactivestatus from "../store/services/fyersactivestause"
import Fayesrvalidation from "../store/services/fayesrvalidation"

import Loaidngcom from "./loadingcomponent"
import { Redirect, useHistory } from 'react-router-dom';
const CreateAPI = ({tradeStatus}) => {

   
	const history = useHistory();
    let logiid = localStorage.getItem('userid');

	if (logiid === null) {

		let path = "/";
		history.push(path);
		
	}
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [saveapi, setSaveapi] = useState(false)
    const [appname, setAppname] = useState("")
    const [appid, setAppid] = useState("")
    const [appkey, setAppkey] = useState("")
    const [clientid, setClientid] = useState("")
    const [secrectid, setSecretid] = useState("")
    const [pinid, SetPinid] = useState("")
    const [fayersactive, setFayersactive] = useState([])
    const authState = useSelector(state => state.authState)
    const apicreate = useSelector(state => state.apicreate);
    const userId = localStorage.getItem('userid')
    const dispatch = useDispatch();
    const uid = userId
    const [isedting, setIsediting] = useState(false)
    const [editingfayersapi, setEdtingfayersapi] = useState(null)

    const [editfid, setEditFid] = useState("")
    const [editappname, setEditappname] = useState("")
    const [editappid, setEditappid] = useState("")
    const [editclientid, setEditclientid] = useState("")
    const [editsecid, setEditsecid] = useState("")
    const [editpin, setEditpin] = useState("")
    const [edituserid, setEdituserid] = useState("")
    const [apiactivedata, setApiactivedata] = useState([])
    const [activefayersapi, setActivefayersapi] = useState(false)
    const [activslider, setActiveclider] = useState()
    const [valiedform, setValiedform] = useState(true)

    // useEffect(() => {
    //     let userid = userId
    //     Fyersactivestatus.fyersactive(userid).then((apires) => {
    //         <Loaidngcom />
    //         
    //         // setApiactivedata(apires.data.data)
    //         setActiveclider(apires.data.data.status)
    //     })
    //         .catch((error) => {
    //             console.log("faild")
    //         })

    // },[])

    const UpdateSt = (e, cItem, field) => {
           debugger 
        const newState = fayersactive.map(obj => {
            if (cItem.fyersID == obj.fyersID) {
                let newObj = { ...obj };
                if (e == true) {
                    newObj[field] = "true"
                }
                else {
                    newObj[field] = "false"
                }
                return newObj;
            }
            return obj;
        });
        setFayersactive(newState);
        console.log(newState)
        debugger
        if (e !== true) {
            let fyersid = cItem.fyersID
            const params = { Fid: fyersid }
            fyersPostdesibalservices.fyesrpostdisable(params).then((apiRes) => {
                <Loaidngcom/>
                if (apiRes.data.isSuccess == true) {
                    toast.info("API Has been Inactive", {
                        position: "top-center"
                    })
                }

            })
                .catch((error) => {
                    console.log("faild")
                });
        } else {
            debugger
            let appid = cItem.appId
            let fyersID = cItem.fyersID
            // the sotware url is notworkig for fayers
            window.location.href = "https://api.fyers.in/api/v2/generate-authcode?client_id=" 
             + appid 
             + "&redirect_uri=http://software.asiabullalgo.in/fyerssuccessful&response_type=code&state=" 
             + fyersID;

            // window.open("https://api.fyers.in/api/v2/generate-authcode?client_id="+ appid +"&redirect_uri=http://httpshost:3000/fyerssuccessful&response_type=code&state="+fyersID)

        }
    }

    const apicrating = () => {
        debugger
        dispatch(action.apicreatestart());
        const params = { FyersId: 'MHIuYi5zb2Z0', UserId: uid, ClientID: clientid, AppId: appid, SecretId: secrectid, AppName: appname, Pin: pinid };
        
        if (uid && clientid && appid && secrectid && appname && pinid) {
            // Show loading
            <Loaidngcom />
            
            // Call API creation function
            apicration.apicre(params).then((apiRes) => {
                dispatch(action.apicreatestart(apiRes)); // Dispatch success
                console.log(apiRes);
                
                if (apiRes.data.isSuccess) {
                    // Clear form fields
                    setAppname('');
                    setAppid('');
                    setClientid('');
                    setSecretid('');
                    SetPinid('');
                    setModal1Open(false);
                    
                    // Show success message
                    toast.info("API has been created successfully", {
                        position: "top-center"
                    });
                }
            }).catch((error) => {
                console.log("Failed to create API");
                
                // Show error message
                toast.error("Failed to create API", {
                    position: "top-center"
                });
            });
        } else {
            // Show error if any field is empty
            toast.error("All fields must be filled to create the API", {
                position: "top-center"
            });
        }
        


    }

    const openApicraationmodule = () => {
        setEditFid("")
        setClientid("")
        setEditappid("")
        setEditsecid("")
        setEditappname("")
        setEditclientid("")
        setEditpin("")
        setModal1Open(true)

    }

    const getfayerslist = (event) => {
        debugger
        const uid = userId
        const fid = 0
        FayersAPIlist.fayersacetivelist(uid, fid).then((apiRes) => {
            debugger
            <Loaidngcom />
            setFayersactive(apiRes.data.data)
            setModal2Open(true)
        })
            .catch((error) => {
                console.log("faild")

            });
    }

    const editshow = (record) => {
        debugger
        setIsediting(true)
        setEdtingfayersapi({ ...record })
        setEditFid(record.fyersID)
        setClientid(record.clientId)
        setEditappid(record.appId)
        setEditsecid(record.secretId)
        setEditappname(record.appName)
        setEditclientid(record.clientId)
        setEditpin(record.pin)
        setEdituserid(userId)
    }

    const editrecoerd = () => {
        debugger
        dispatch(action.apicreatestart());
        const params = { fyersId: editfid, UserId: edituserid, ClientID: editclientid, AppId: editappid, SecretId: editsecid, AppName: editappname, Pin: editpin };

        apicration.apicre(params).then((apiRes) => {
            <Loaidngcom />
            if (apiRes.data.isSuccess == true) {
                getfayerslist()
                setIsediting(false)
            }

            toast.info("API Has Been updated Sucessfully", {
                position: "top-center"
            })
        })
            .catch((error) => {

                console.log("faild")

            });
    }

    const deleteapi = (record) => {
        
        let fyersid = record.fyersID
        let userid = userId
        const params = { fyersId: fyersid, UserId: userid };
        console.log(params)
        Apideletion.apidelete(params).then((apiRes) => {
            
            <Loaidngcom />
            if (apiRes.data.isSuccess == true) {
                getfayerslist()
            }

        })

            .catch((error) => {
                console.log("faild")
            });
    }

    const fetfayersapi = (record) => {
        setActivefayersapi(!activefayersapi)
        if (activefayersapi == true) {
            let fyersid = record.fyersID
            const params = { Fid: fyersid }
            fyersPostdesibalservices.fyesrpostdisable(params).then((apiRes) => {

                if (apiRes.data.isSuccess == true) {
                    toast.info("API Has been Inactive", {
                        position: "top-center"
                    })
                }

            })

                .catch((error) => {
                    console.log("faild")
                });
        } else {

            let appid = record.appId
            let fyersID = record.fyersID
             window.open("https://api.fyers.in/api/v2/generate-authcode?client_id="+appid+"&redirect_uri=http://www.software.asiabullalgo.in/fyerssuccessful&response_type=code&state="+fyersID)  
            // window.open("https://api.fyers.in/api/v2/generate-authcode?client_id=" + appid + "&redirect_uri=http://httpshost:3000/fyerssuccessful&response_type=code&state=" + fyersID)

        }
    }

    const validationclientid = (e) => {

        setClientid(e.target.value)
        Fayesrvalidation.fayersvalidationchek(clientid, 0, 0).then((apiRes) => {

            if (apiRes.data.message == "Record Found") {

                toast.dark("Client Id is allradey exits", {
                    position: "top-center"
                })
                setClientid("")

            } else {

            }

        })
            .catch((error) => {

                console.log("faild")
            });
    }

    const validationappid = (e) => {
    debugger    

        setAppid(e.target.value)
        Fayesrvalidation.fayersvalidationchek(0, appid, 0).then((apiRes) => {

            if (apiRes.data.message == "Record Found") {

                toast.dark("App Id is allradey exits", {
                    position: "top-center"
                })
                setAppid("")
            }
            else {
                /* else Part  */
            }

        })
            .catch((error) => {

                console.log("faild")
            });
    }

    const validationsecreatid = (e) => {

        setSecretid(e.target.value)
        Fayesrvalidation.fayersvalidationchek(0, 0, secrectid).then((apiRes) => {

            if (apiRes.data.message == "Record Found") {

                toast.dark("SecretId Id is allradey exits", {
                    position: "top-center"
                })
                setSecretid("")

            } else {

            }
        })
            .catch((error) => {

                console.log("faild")
            });
    }

    const colums = [
        {
            title: "FyersId",
            dataIndex: "fyersID",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.fyersId - b.fyersId,
                multiple: 1,
            }
        },
        {
            title: "ClientId",
            dataIndex: "clientId",
            align: "center",

        },
        {
            title: "AppName",
            dataIndex: "appName",
            align: "center"
        },
        {
            title: "Active/Inactive",
            dataIndex: "tokanStatus",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive"
                            checked={(record.tokanStatus == "true") ? true : false}
                            // onChange={() => fetfayersapi(record)}
                            onChange={(e) => {UpdateSt(e, record, "tokanStatus") }}
                        />
                    </>
                )
            }

        },
        {
            title: "Edit",
            dataIndex: "action",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Button type='primary' onClick={() => { editshow(record) }}>
                            Edit
                            <EditOutlined />
                        </Button>
                    </>
                )
            }

        },
        {
            title: "Delete",
            dataIndex: "action",
            align: "center",
            render: (_, record) => {
                return (
                    <>

                        <Popconfirm title={" Do you want to Delete this Record..?? \n (Note: if delete this record then Strategy Allocation record also deleted)"}
                            onConfirm={() => { deleteapi(record) }}
                        >
                            <Button danger>
                                Delete
                                <DeleteOutlined />
                            </Button>

                        </Popconfirm>
                    </>
                )
            }

        },



    ]

    const modifydata = fayersactive.map(({ ...item }) => ({
        ...item,
        key: item.fyersId,
    }))
    return (
        <>
            <Button type="primary" onClick={() => openApicraationmodule()}>
                Create API
            </Button>
            <Modal
                title="Create API"
                style={{
                    top: 20,
                }}
                open={modal1Open}
                onOk={() => { apicrating() }}
                okType='default'
                okText="Save API"
                onCancel={() => setModal1Open(false)}
                className='Createapi'

            >
                <Form>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>App Name :</label>
                            <Input type="text"  value={appname} size="large"
                                id="appname"
                                onChange={(e) => setAppname(e.target.value)}

                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>App Id :</label>
                            <Input type="text" size="large"
                                id="appid" name="appid" value={appid}
                                onChange={(e) => setAppid(e.target.value)}
                                onBlur={(e) => validationappid(e)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Client id  :</label>
                            <Input type="text" size="large"
                                id="Clientid" name="Clientid" value={clientid}
                                onChange={(e) => setClientid(e.target.value)}
                                onBlur={(e) => validationclientid(e)}

                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Secreacte id   :</label>
                            <Input type="text" size="large"
                                id="Secreacteid" name="Secreacteid" value={secrectid}
                                onChange={(e) => setSecretid(e.target.value)}
                                onBlur={(e) => validationsecreatid(e)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Pin   :</label>
                            <Input type="text" size="large"
                                id="Pinid" name="Pinid" value={pinid}
                                onChange={(e) => SetPinid(e.target.value)}
                            />
                        </div>
                    </div>
                </Form>
            </Modal>

            <Button type="primary" onClick={() => getfayerslist()}>
                Edit API
            </Button>
            <Modal
                title="Edit API"
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                width={1000}
                className='Editapi'

            >
                <Table
                    columns={colums}
                    dataSource={modifydata}
                    bordered
                    scroll={{ x: true}}
                />
            </Modal>
            <Modal
                title="Editing"
                open={isedting}
                onOk={() => editrecoerd()}
                okText="Save Changes"
                onCancel={() => setIsediting(false)}
                width={400}
            >
                <div className='modal-body'>
                    <div className="mb-3">
                        <label hidden htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>fyersId  :</label>
                        <Input disabled hidden value={editingfayersapi?.fyersID} onChange={(e) => {
                            setEdtingfayersapi(pre => {
                                return { ...pre, fyersID: setEditFid(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>App Name :</label>
                        <Input name='editappname' id='editappname' value={editingfayersapi?.appName} onChange={(e) => {
                            setEdtingfayersapi(pre => {
                                return { ...pre, appName: setEditappname(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>App Id :</label>
                        <Input name='editappid' id='editappid' value={editingfayersapi?.appId} onChange={(e) => {
                            setEdtingfayersapi(pre => {
                                return { ...pre, appId: setEditappid(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Client id  :</label>
                        <Input
                            name='editclientid' id='editclientid'
                            value={editingfayersapi?.clientId}
                            onChange={(e) => {
                                setEdtingfayersapi(pre => {

                                    return { ...pre, clientId: setEditclientid(e.target.value) }

                                })
                            }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Secreacte id :</label>
                        <Input name='editappname' id='editappname' value={editingfayersapi?.secretId} onChange={(e) => {
                            setEdtingfayersapi(pre => {
                                return { ...pre, secretId: setEditsecid(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Pin :</label>
                        <Input name='editappname' id='editappname' value={editingfayersapi?.pin} onChange={(e) => {
                            setEdtingfayersapi(pre => {
                                return { ...pre, pin: setEditpin(e.target.value) }
                            })
                        }}>
                        </Input>
                    </div>

                </div>
            </Modal>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    );
};
export default CreateAPI;