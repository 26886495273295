

import https from "../../utility/api-client";

class Showprofitandlossdata 
{
    showprofitandlossdata (userid,fyersid,Tradedate,)
    {

       
        return https.get('/Fyers/GetTradeData?UserId='+ userid +'&FyersId='+ fyersid+'&TradeDate='+ Tradedate)
  
    }
}

export default new Showprofitandlossdata