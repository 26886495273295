import Headerdashbord from "./Headerdashbord.js"

import Dashboardbox from "./Dashbordbox.js"
import { FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoMdTrendingUp } from "react-icons/io";
import { GiPowerButton } from "react-icons/gi";
import Dashbordapicard from "../component/Dashapiworking"
import Dashbordstgcard from "../component/Dashactivestg"
import Totalreaferlcard from './Dashtotalrefer';
import DashboardTradeonoff from "./Dashbordtradeonoff.js";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Tooltip, Table, Switch, Tabs } from 'antd';
import { useDispatch, useSelector, } from "react-redux";

import * as actions from '../store/actions/Index'
import chekuser from '../store/services/chekuser'
import Whatsnew from '../component/WhatsNew'
import Loginhistory from '../component/LoginHistory'
import Apiactivestatus from "../component/Apistatus"
import Hookdata from "../component/Hoohkdatamodel"
import Loaidngcom from "../component/loadingcomponent"
import Fyersapicount from "../store/services/Fyesrapicount"
import { Avatar, Badge, Space, Progress, Dropdown, Menu } from 'antd';
import Tradeonoff from "../store/services/Tradeonoff"
import Fyersactivestatus from "../store/services/fyersactivestause"
import { ToastContainer, toast } from 'react-toastify';
import Remaningdyassoft from "../store/services/remaningdays"
import { constant, round, values } from 'lodash';
import Header from './Header';
import Meta5activestause from '../store/services/Meta5activestause';
import {
	BellOutlined, UserOutlined, PoweroffOutlined, WalletOutlined, HomeOutlined, DashboardOutlined
} from '@ant-design/icons';
import Sidebar from "./sidebar.js";
import Meata5ApiCount from '../store/services/Meata5ApiCount';
import Tradingerrorlog from "./Tradeerrologs"
import { Visibility } from "@mui/icons-material";
import MembershipExpiryPieChart from "./Chartdyasreamin.js";
import Fillallapilist from "../store/services/Fillallapilist.js";
import { Redirect, useHistory } from 'react-router-dom';
const Dashboard = () => {
	debugger
	const history = useHistory();
	const { TabPane } = Tabs;
	const [messages, setMessages] = useState([]);
	const [brokersapi, setBrokersapi] = useState([])
	const [meta5count, setmeta5count] = useState([])
	const authState = useSelector(state => state.authState)
	const [hookdatamodel, setHookdatamodel] = useState(false)
	const [hookdatarefersh, setHookdatarefersh] = useState(false)
	const [remaindays, setRemaindays] = useState(75)
	const [plandays, setPlandays] = useState(100)
	const [dayspersent, Setdayspersent] = useState("")
	const [progrsscolure, setprogrsscolure] = useState("")
	const [isLoading, setIsLoading] = useState(false);

	let dispatch = useDispatch()

	const userId = localStorage.getItem('userid');
	const username = localStorage.getItem('username')
	const userauth = localStorage.getItem('auth')
	const usertradestaus = localStorage.getItem('tstatus')

	const [msgforonoff, setMsgforonoff] = useState("")
	const [apiactivedata, setApiactivedata] = useState([])
	const [apiactivemeta5data, setapiactivemeta5data] = useState([])
	const [linedata, setlinedata] = useState([])

	const [fyesrimgpath, setfyesrimgpath] = useState("")
	const [tstatusonoff, setTstatusonoff] = useState(false)

	const [allapilistdata,setallapilistdata] = useState()

	let logiid = localStorage.getItem('userid');

	if (logiid === null) {

		let path = "/";
		history.push(path);
		
	}

	useEffect(() => {

		let uid = localStorage.getItem('userid');
		chekuser.chkuser(uid).then((apires) => {

			console.log(apires.data.data.tradeStatus)


			if (apires.data.data.tradeStatus === "true") {
				setTstatusonoff(true)
			} else {
				setTstatusonoff(false)
			}
		})
			.catch((error) => {
				// dispatch(actions.loginFail(error));
				console.log("faild")
				console.log(error)

			})



	}, [tstatusonoff])

	const userinnfo = () => {


		dispatch(actions.loginSuccess(0, userId))

		let uid = localStorage.getItem('userid');

		const params = null

		chekuser.chkuser(uid).then((apires) => {
			<Loaidngcom />
			dispatch(actions.loginSuccess(0, uid));

			console.log(apires.data.data.profileName)
		})
			.catch((error) => {
				// dispatch(actions.loginFail(error));
				console.log("faild")
			})

	}
	const dasdata = [
		{ id: "1", dashtital: "Plan Expiery Days", dashtitalnum: "365" },
		{ id: "2", dashtital: "No of API working", dashtitalnum: "26" },
		{ id: "3", dashtital: "No Of Strategy Active", dashtitalnum: "2" },
		{ id: "4", dashtital: "Total Referral", dashtitalnum: "21" },
		{ id: "5", dashtital: "Credit Points", dashtitalnum: "201" }
	]
	let dashbordata = dasdata.map((values) => {
		return (
			<div key={values.id} className="expdate col-md-2">
				<div className="dashicon">
					<h4 className="bi bi-person-fill"></h4>
				</div>
				<div className="dashinfo">
					<p>{values.dashtitalnum}<br>
					</br>{values.dashtital}</p>
				</div>
			</div>
		)
	})


	const pldata = [
		{
		  t_id: 1,
		  t_date: 8,
		  user_id: 2,
		  fyers_id: 2,
		  profitloss: 124,
		  Det: [
			{
			  t_det_id: 3,
			  T_Id: 1,
			  symbol: "Nifty",
			  symbol_contract: "06FEB2025",
			  product: "INTRADAY",
			  type: "Buy",
			  avg_price: "",
			  det_profitloss: 1245,
			  close_ltp: "",
			},
		  ],
		},
		{
			t_id: 2,
			t_date: 8,
			user_id: 2,
			fyers_id: 2,
			profitloss: 124,
			Det: [
			  {
				t_det_id: 3,
				T_Id: 1,
				symbol: "Nifty",
				symbol_contract: "06FEB2025",
				product: "INTRADAY",
				type: "Buy",
				avg_price: "",
				det_profitloss: 1245,
				close_ltp: "",
			  },
			],
		},
		{
			t_id: 3,
			t_date: 8,
			user_id: 2,
			fyers_id: 2,
			profitloss: 124,
			Det: [
			  {
				t_det_id: 3,
				T_Id: 1,
				symbol: "Nifty",
				symbol_contract: "06FEB2025",
				product: "INTRADAY",
				type: "Buy",
				avg_price: "",
				det_profitloss: 1245,
				close_ltp: "",
			  },
			],
		}
	  ];
	  
	  let dashbordpldata = pldata.map((val, index) => {
		return (
		  <div key={index} style={{
			
		  }}>
			<div>
			  {val.Det.map((det) => (
				<div >
					<div>
						<h5>{det.symbol} {det.symbol_contract}</h5>
						<h5>{det.det_profitloss}</h5>
					</div>
					<div>
						<span>{det.product}</span> {det.type}
					</div>
				</div>
			  ))}
			</div>
		  </div>
		);
	  });




	// {
	// "AccountId": 9,
	// "Bloodgroup": "AB negative",
	// "Complexion": "Dark",
	// "Diet": "Vge",
	// "Gender": "Male",
	// "HeightId": 7,
	// "Ifothercast: ""
	// "Lens": "No",
	// "MaritalStatus: "Divorced",
	// "MtId": 3,
	// "PhysicalProb": "Yes",
	// "Spectacle": "No",
	// "UserFirstname": "sad",
	// "Weight": "41", 	
	// "castId": 165,
	// "dateofbirth": "2024-07-05",
	// "dateofbirthTime":" "14:53:07",
	// "maritalStatus": "Divorced",
	// "mtName": "Bengali",
	// "religionId": 6,
	// "subcastId": 6,
	// "userId": 0,
	// "userMiddlename": "adads"
	// "userLastname: ""

	// }

    const onofftrade = (e) => {
           
        let useronoff = e.target.checked
        let userid = localStorage.getItem('userid');
        let fyersid = "A";
        let formtag = 0
        Tradeonoff.tradeonoff(userid, fyersid, useronoff, formtag).then((apiRes) => {
            console.log(apiRes)
               

            if (useronoff == true) {
                   
                setTstatusonoff(true)
                setMsgforonoff()
                toast.info("Tarde service Is Now ON", {
                    position: "top-center"
                })
                // localStorage.setItem('tstatus', true)
            } else {
                setTstatusonoff(false)
                toast.info("Tarde service Is Now Off", {
                    position: "top-center"
                })
                // localStorage.setItem('tstatus', false)
            }



        })
            .catch((error) => {
                console.log("faild")
            });
    }

	useEffect(() => {


		let userid = localStorage.getItem('userid');
		Fyersapicount.fyerscountapi(userid).then((apires) => {

			setBrokersapi(apires.data.data)

		})
			.catch((error) => {
				console.log("faild")

			})

	}, [])


	useEffect(() => {


		let userid = localStorage.getItem('userid');
		Meata5ApiCount.meta5apicount(userid).then((apires) => {

			setmeta5count(apires.data.data)

		})
			.catch((error) => {
				console.log("faild")

			})

	}, [])



	const workingpartners = [
		{ id: "1", workingAPI: brokersapi, apis: '../assets/images/Fyers.png' },
	];

	const workingpart = workingpartners.map((values) => (
		<>
			<div key={`${values.id}-fyers`} className="workingAPIpartners mb-5 col-lg-3 col-md-6 col-sm-6" >
				<Badge count={brokersapi}>
					<img
						src={require('../assets/images/Fyers.png')}
						height={65}
						alt="workingpartnerApi"
						style={{ width: "120px" }}
					/>
				</Badge>
			</div>

			<div key={`${values.id}-meta5`} className="workingAPIpartners mb-5 col-lg-3 col-md-6 col-sm-6">
				<Badge count={meta5count}>
					<img
						src={require('../assets/images/MetaTrader5-removebg.png')}
						height={65}
						alt="workingpartnerApi"
						style={{ width: "120px" }}
					/>
				</Badge>
			</div>
		</>
	));



	// const onofftrade = (e) => {
	// 	   
	// 	let useronoff = e.target.checked
	// 	let userid = localStorage.getItem('userid');
	// 	let fyersid = "A";
	// 	let formtag = 0
	// 	Tradeonoff.tradeonoff(userid, fyersid, useronoff, formtag).then((apiRes) => {
	// 		console.log(apiRes)
	// 		   

	// 		if (useronoff == true) {
	// 			setTstatusonoff(true)
	// 			setMsgforonoff()
	// 			toast.info("Tarde service Is Now ON", {
	// 				position: "top-center"
	// 			})
	// 			// localStorage.setItem('tstatus', true)
	// 		} else {
	// 			setTstatusonoff(false)
	// 			toast.info("Tarde service Is Now Off", {
	// 				position: "top-center"
	// 			})
	// 			// localStorage.setItem('tstatus', false)
	// 		}



	// 	})
	// 		.catch((error) => {
	// 			console.log("faild")
	// 		});
	// }




	useEffect(() => {

		let userid = userId
		   
		Fyersactivestatus.fyersactive(userid).then((apires) => {
			   
			setApiactivedata(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")

			})




	}, [tstatusonoff])


	// useEffect(()=>{

	// 	Fillallapilist.filallapilist(userId).then((apires)=>{
	// 		setallapilistdata(apires.data.data)

	// 	}).catch((error)=>{
	// 		console.log("error")
	// 	})

	// },[])


	useEffect(() => {
		   
		let userid = userId

		Meta5activestause.meta5active(userid).then((apires) => {
			   
			setapiactivemeta5data(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")

			})




	}, [tstatusonoff])

	const fyerscolums = [
		{
			title: "ClientId",
			dataIndex: "clientId",
			align: "center"
		},
		{
			title: "Status",
			dataIndex: "status",
			align: "center",
			render: (text, record) => {
				const statusStyle = record.status === "Active" ? { color: "green" } : { color: "red" };
				return <span style={statusStyle}>{text}</span>;
			}
		},
		{
			title: "Trade On/Off",
			dataIndex: "tradeStatus",
			align: "center",
			render: (_, record) => {
				return (
					<>
						<Switch checkedChildren="On" unCheckedChildren="Off"
							checked={(record.tradeStatus == "true") ? true : false}
							// onChange={() => fetfayersapi(record)}
							disabled={tstatusonoff != true}
							onChange={(e) => { UpdateSt(e, record, "tradeStatus") }}
						/>
					</>
				)
			}
		},
		// {
		// 	title: "formtag",
		// 	dataIndex: "formtag",
		// 	align: "center",
		// },
	]


	const meta5colums = [
		{
			title: "ClientId",
			dataIndex: "mtId",
			align: "center",


		},
		{
			title: "Status",
			dataIndex: "status",
			align: "center",
			render: (text, record) => {
				const statusStyle = record.status === "Active" ? { color: "green" } : { color: "red" };
				return <span style={statusStyle}>{text}</span>;
			}
		},
		{
			title: "Trade On/Off",
			dataIndex: "tradeStatus",
			align: "center",
			render: (_, record) => {
				return (
					<>
						<Switch checkedChildren="On" unCheckedChildren="Off"
							checked={(record.tradeStatus == "true") ? true : false}
							// onChange={() => fetfayersapi(record)}
							disabled={tstatusonoff != true}
							onChange={(e) => { UpdateSt(e, record, "tradeStatus") }}
						/>
					</>
				)
			}
		},
		// {
		// 	title: "formtag",
		// 	dataIndex: "formtag",
		// 	align: "center",		   
		// },
	]



	const UpdateSt = (e, cItem, field) => {
		   

		if (cItem.formtag == 1) {

			const newState = apiactivedata.map(obj => {
				if (cItem.fyersId == obj.fyersId) {

					let newObj = { ...obj };
					if (e == true) {
						newObj[field] = "true"
					}
					else {
						newObj[field] = "false"
					}
					return newObj;
				}
				return obj;
			});
			setApiactivedata(newState)
			console.log(newState)

		} else {


			const newState = apiactivemeta5data.map(obj => {
				if (cItem.mtId == obj.mtId) {

					let newObj = { ...obj };
					if (e == true) {
						newObj[field] = "true"
					}
					else {
						newObj[field] = "false"
					}
					return newObj;
				}
				return obj;
			});
			setapiactivemeta5data(newState)
			console.log(newState)

		}



		let useronoff = e
		let userid = 0
		let fyersid = cItem.formtag == 1 ? cItem.fyersId : cItem.mtId;
		let formtag = cItem.formtag
		if (tstatusonoff != false) {
			setIsLoading(true); // Start loading

			Tradeonoff.tradeonoff(userid, fyersid, useronoff, formtag).then((apiRes) => {


				console.log()
				setIsLoading(false); // Stop loading
				if (useronoff == true) {

					toast.info("Trade On", {
						position: "top-center"
					})
				} else {
					toast.info("Trade Off", {
						position: "top-center"
					})
				}

			})
				.catch((error) => {
					console.log("faild")

				});


		} else {
			toast.error("Please Start Trade Service", {
				position: "top-center"
			})

		}

		return (
			<div className="App">
				{isLoading && <isLoading />}
				{/* Your other components and JSX */}
			</div>
		);


	}


	useEffect(() => {
		   
		let userId = localStorage.getItem('userid');
		let softytype = "Soft"
		Remaningdyassoft.remaningdayssoft(userId, softytype).then((apiRes) => {

			setlinedata(apiRes.data.data)

		})
			.catch((error) => {
				console.log("faild")

			});

	}, [])

	const datas = linedata.map(plan => {

		let percent = (plan.iplanDays / plan.invtRemainDays)
		let dayspesrcent = (100) - (round(100 / percent))

		return (
			<div key={plan.invtInvId}>
				<h6 style={{ textAlign: "left", color: "red	" }}>Invoice:- {plan.invtInvId}</h6>
				<Progress key={plan.invtInvId} size="default" type="line" strokeLinecap="round" strokeColor={progrsscolure} percent={plan.daysPer} format={({ dayspersent }) => `${plan.invtRemainDays}`} />
			</div>
		)

	});

	return (

		<>
			<div className="right-content w-100">
				<div className="row dashbordBoxwraperrow">
					<div className="col-md-8">
						<div className="dashbordBoxwraper d-flex">
							<Dashbordapicard />
							<Dashbordstgcard />
							<Totalreaferlcard />
							<div className="dashbordbox" style={{ backgroundImage: 'linear-gradient(to right, #e1950e, #f3cd29)', position: 'relative' }}>
								<div className="d-flex w-100">
									<div className="col1">
										<h4 className="text-white mb-0">Trade service </h4>
										<span className="text-white">{tstatusonoff == true ? "On" : "Off"}</span>
									</div>
									<div className="icon-container">
										<span className="icon"><FaUserCircle /></span>
									</div>
								</div>
								<div className="onoffbutton">
									<div className='dshbordimgdiv' style={{ marginLeft: "15px", marginTop: "15px" }}>
										<div className='poweronoff'>
											<div className='circle'>
												<input type='checkbox' checked={(tstatusonoff == true ? true : false)} onChange={(e) => onofftrade(e)}></input>
											</div>
										</div>
									</div>
								</div>

								<ToastContainer
									position="top-right"
									autoClose={500}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme="dark"

								/>

							</div>
						</div>
					</div>
					<div className="col-md-4 pl-0 topPart2">
						<div className="dashbordboxtow">
							<div className="d-flex w-100">
								<div className="col1">
									<h3 className="text-white mb-0">Membership Plan </h3>
									<h5 className="text-white font-wight-bolder mb-4">Remaining Day's</h5>
									<div className="remaindayschart">
										<MembershipExpiryPieChart />
									</div>
								</div>
							</div>
							<div className="buttononoff">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="workingpratner-content" >
				<Row>
					<Col lg={7} md={12} >
						<Card className="dashboard-card" >
							<div className="row mb-5 col-md-12" style={{ margin: "10px" }} >
								{workingpart}
							</div>
						</Card>
					</Col>

					<Col lg={5} md={12}>
						<Card className="dashboard-card">
							<div className="ActivatedFyers">
								<Tabs defaultActiveKey="1" className="custom-tabs">
									<TabPane tab="Active Fyers API" key="1">
										<div className="table-responsive mt-3">
											<Table
												columns={fyerscolums}
												dataSource={apiactivedata}
												scroll={{ x: true }}
												pagination={{ pageSize: 7 }}
											/>
										</div>
									</TabPane>
									<TabPane tab="Active Meta-5 API" key="2">
										<div className="apiactivelist">
											<Table
												columns={meta5colums}
												dataSource={apiactivemeta5data}
												scroll={{ x: true }}
												pagination={{ pageSize: 5 }}
											/>
										</div>
									</TabPane>
								</Tabs>
							</div>
						</Card>
					</Col>

				</Row>

				<Row>
				
					<Col lg={12}>
						<Card className="dashboard-card">
							<div className='tradeloghead'>
								<h5>Trading Error Log</h5>
							</div>
							<Tradingerrorlog />
						</Card>
					</Col>

					<Col lg={12}>
						<Card className="dashboard-card">
							<Hookdata />
						</Card>
					</Col>
				</Row>

				<Row>
					<Col lg={6} md={12}>
						<Card className="dashboard-card">
							<div className="Whatsnew col-md-12">
								<div className='rightdivhead'>
									<h5>What's New?</h5>
								</div>
								<Whatsnew />
							</div>
						</Card>
					</Col>

					<Col lg={6} md={12}>
						<Card className="dashboard-card">
							<div className="LoginHistory col-md-12">
								<div className='rightdivhead'>
									<h5>Login History</h5>
								</div>
								<Loginhistory />
							</div>
						</Card>
					</Col>
				</Row>

			</div>
		</>
	)
}

export default Dashboard