import https from '../../utility/api-client'

class Profitandlossdash
{
    
    profitandlossdash(params)
    {
        return https.post('/Fyers/PostTradeData', params);
    }
}

export default new Profitandlossdash();