import Header from "./Header"
import CustomCalendar from "./CustomCalendar";

const Telegramchanel = () => {
	
	return (
		
		<div style={{ display: "flex", justifyContent: "center", marginTop: "150px" }}>
		<CustomCalendar />
	  </div>

)

}

export default Telegramchanel