import React, { useRef } from "react";
import { Form, Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useEffect } from 'react';
import * as actions from '../store/actions/Index';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createemailotp from '../store/services/createemailotp'
import checkemailotp from '../store/services/checkemailotp'
import { Redirect, useHistory } from 'react-router-dom';
import checkmail from '../store/services/checkmail'
import resetfgpass from "../store/services/resetfgpass"
import Loaidngcom from "../component/loadingcomponent"
import { Input, Row } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
const Frogotpassword = () => {
    const [frgminutes, setFrgminutes] = useState(0);
    const [frgseconds, setFrgmSeconds] = useState(30)
    const [frgruning, setFrgmRuning] = useState(false)
    const [useremailotp, setEmailotp] = useState("")
    const [sendotpmobile, setSendotpmobile] = useState(false)
    const [sendotpemail, setSendotpemail] = useState(false)
    const [useremail, setUseremail] = useState("")
    const [usermobile, setUsermobile] = useState("")
    const [valiedemail, setValiedemail] = useState(false)
    const [resendemaileotp, setResendemaileotp] = useState(false)
    const [otpmsg, setOtpmsg] = useState("")
    const dispatch = useDispatch();
    const history = useHistory()
    const regExp = /[a-zA-Z0-9.%+-]+@[a-z0-9-]+\.[a-z]{2,8}(.[a-z{2,8}])?/;
    const [otpMatched, setOtpMatched] = useState(false);
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const otpRefs = useRef([]);

    const handleOTPChange = (e, index) => {
        const value = e.target.value;
    
        if (/^\d$/.test(value)) {
            setEmailotp((prev) => {
                const newOtp = [...prev];
                newOtp[index] = value;
    
                // Combine OTP array into a single string
                setEmailotp(newOtp.join(""))
                console.log("Combined OTP:", useremailotp); // Output OTP as a string
    
                return newOtp;
            });
    
            if (index < 5) {
                otpRefs.current[index + 1]?.focus();
            }
        } else {
            e.target.value = ""; // Prevents entering non-numeric characters
        }
    };
    

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpRefs.current[index - 1]?.focus();
        }
    };

    const formik = useFormik({
        initialValues: {
            inputemail: "",
            newpassword: "",
            confirmpassword: ""
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            inputemail: Yup.string()
                .email("Plese enter Valid Email address"),
            newpassword: Yup.string()
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:")
                .required("Plase Enter Password"),
            confirmpassword: Yup.string()
                .oneOf([Yup.ref('newpassword')], "Password Not Match")
                .required("Plase Enter confirmpassword"),
        }),

    })

    useEffect(() => {
        if (frgruning) {

            const interval = setInterval(() => {
                if (frgseconds > 0) {
                    setFrgmSeconds(frgseconds - 1);
                }

                if (frgseconds === 0) {
                    if (frgminutes === 0) {
                        clearInterval(interval);
                    } else {
                        setFrgmSeconds(59);
                        setFrgminutes(frgminutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);

            };
        }
    });

    const chekemail = () => {
        if (!useremail || !regExp.test(useremail)) {
            toast.error("Please enter a valid email", { position: "top-center" });
            return;
        }

        setIsLoading(true);
        dispatch(actions.checkemail());

        checkmail.chkmemail(useremail)
            .then((apiRes) => {
                dispatch(actions.checkemail(apiRes));
                console.log(apiRes);

                if (!apiRes.data.data.email) {
                    toast.error("Email is not authorized", { position: "top-center" });
                    setSendotpemail(true);
                    setValiedemail(false);
                } else {
                    setSendotpemail(false);
                    setValiedemail(true);
                }
            })
            .catch((error) => {
                dispatch(actions.checkemail(error));
                console.log("Failed");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const postemailotp = () => {
        debugger
        if (!valiedemail) {
            toast.error("Please enter a valid email", { position: "top-center" });
            return;
        }

        setIsLoading(true);
        dispatch(actions.emailotpsend());

        const params = { Email: useremail };

        createemailotp.creemailotp(params)
            .then((apiRes) => {
                dispatch(actions.emailotpsend(apiRes));
                console.log(apiRes.data.data.emailotp);
                setEmailotp(apiRes.data.data.emailotp);

                toast.success(`OTP Sent: ${apiRes.data.data.emailotp}`, { position: "top-center" });

                // Prevent resending OTP for 30 seconds
                setSendotpemail(true);
                setResendemaileotp(true);
                setFrgmRuning(true);
                setFrgminutes(0);
                setFrgmSeconds(30);
                setStep(2);
            })
            .catch((error) => {
                console.log("Failed to send OTP");
                toast.error("Failed to send OTP. Try again.", { position: "top-center" });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const ckemailotp = () => {
        debugger
        const email = useremail
        const otpnum = useremailotp
        dispatch(actions.emailotpcheck());

        checkemailotp.chkmobileotp(email, otpnum).then((apiRes) => {
            <Loaidngcom />

            dispatch(actions.emailotpcheck(apiRes));
            console.log(apiRes)

            setOtpmsg(apiRes.data.message)

            if (apiRes.data.data.emailotp !== "") {
                toast.info(apiRes.data.message, {
                    position: "top-center"
                })
                setResendemaileotp(false)
                setFrgminutes(0);
                setFrgmSeconds(0);
                setOtpMatched(true);
                setStep(3)
            } else {
                toast.warn(apiRes.data.message, {
                    position: "top-center"
                })
                setResendemaileotp(true)
                setFrgminutes(0);
                setFrgmSeconds(0);
                setOtpMatched(false);
            }

        })
            .catch((error) => {
                console.log("faild")


            });
    }
    const resatepass = (event) => {
        let restemail = useremail
        let restpassword = formik.values.newpassword

        const params = { Email: restemail, Password: restpassword };
        resetfgpass.resetfgpassword(params).then((apiRes) => {
            <Loaidngcom />

            dispatch(actions.forgotpassreset(apiRes))
            console.log(apiRes)
            toast.success("Password Update!", {
                position: "top-center"
            })
            let path = "/SignIn";
            history.push(path);
        })
            .catch((error) => {

                console.log("faild")
                toast.error("Faild to update data   ", {
                    position: "top-center"
                })
            });
    }

    return (
        <div className="container">
            <div className="row Forgotpasswordd col-md-12">


                <div className="Forgotpassword col-md-4 mt-4">
                    <img src={require("../assets/images/Company_logo.png")} height={125} width={125} className="img-fluid" alt="logo" />
                    <h4 className="welcome">Forgot Password? 🔒</h4>
                    <form onSubmit={formik.submitForm}>
                        {step === 1 && (
                            <div className="slide-in-right py-5 ">
                                <div className="d-grid gap-2 mt-5" >
                                    <label htmlFor="inputEmail4" className="form-label">Email</label>
                                </div>
                                <div >
                                    <input type="email" className="form-control mb-0" placeholder="abc@exapmpal.com"
                                        id="inputemail" name="inputemail"
                                        onBlur={chekemail}
                                        onChange={(e) => setUseremail(e.target.value)}
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="button" onClick={postemailotp} className="btn btn-primary" disabled={isLoading || sendotpemail} >
                                        {isLoading ? "Sending..." : "Send OTP"}
                                    </button>

                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div className="slide-in-right py-5">
                                <div className="mt-3 d-flex justify-content-between">
                                    {[...Array(6)].map((_, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="otp-input"
                                            ref={(el) => (otpRefs.current[index] = el)}
                                            onChange={(e) => handleOTPChange(e, index)}
                                            onKeyDown={(e) => handleBackspace(e, index)}
                                        />
                                    ))}
                                </div>

                                <button type="button" className="btn btn-success col-md-12 mt-3" onClick={ckemailotp}>
                                    Verify OTP
                                </button>

                                <div className="countdown-text-email text-center mt-2">
                                    {frgseconds > 0 || frgminutes > 0 ? (
                                        <p>
                                            Time Remaining: {frgminutes < 10 ? `0${frgminutes}` : frgminutes}:
                                            {frgseconds < 10 ? `0${frgseconds}` : frgseconds}
                                        </p>
                                    ) : (
                                        <p>Didn't receive OTP?</p>
                                    )}
                                    <button
                                        className="btn btn-dark"
                                        type="button"
                                        disabled={frgseconds > 0 || frgminutes > 0 || resendemaileotp == false}
                                        style={{
                                            color: frgseconds > 0 || frgminutes > 0 || resendemaileotp == false ? "#DFE3E8" : "#FF5630",
                                        }}
                                        onClick={postemailotp}
                                    >
                                        Resend OTP
                                    </button>
                                </div>
                            </div>

                        )}

                        {step === 3 && (
                            <div className="slide-in-right mt-5">
                                <div className="d-grid gap-2 mt-3">
                                    <Input.Password
                                        placeholder="New Password"
                                        size="large"
                                        id="newpassword" name="newpassword"
                                        prefix={<LockOutlined />}
                                        onChange={formik.handleChange}
                                        value={formik.values.newpassword}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.newpassword && formik.errors.newpassword && <p style={{ color: "red", fontSize: "13px", marginBottom: 5, padding: 0 }}>{formik.errors.newpassword}</p>}
                                    <Input.Password
                                        placeholder="Confirm Password"
                                        size="large"
                                        id="confirmpassword" name="confirmpassword"
                                        prefix={<LockOutlined />}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmpassword}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="button" className="btn btn-dark" onClick={resatepass}>Reset Password</button>
                                </div>
                            </div>
                        )}
                        <div className="new-pplatform mt-3">
                            <a href="/SignIn">Back To Login</a>
                        </div>
                    </form>
                </div>
                <div className="col-md-7">
                    <img src={require("../assets/images/Forgotpassword.jpg")} alt="“Vecteezy.com”" className="img-fluid" style={{ height: "600px", objectFit: "cover", width: "100%" }} />
                </div>


            </div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Frogotpassword   