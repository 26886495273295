import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, DatePicker, message } from "antd";
import Postprofitandloss from "../store/services/Postprofitandloss";
import Showprofitandlossdata from "../store/services/Showprofitandlossdata";

const ProfitAndLossPostData = () => {
	const storedUserId = localStorage.getItem("userid") || "";
	const [form] = Form.useForm();
	const [tdate, setTdate] = useState("");
	const [loading, setLoading] = useState(false);
	const [editingIndex, setEditingIndex] = useState(null); // Track editing row index

	const [masterData, setMasterData] = useState({
		TId: 0,
		TDate: "",
		UserId: storedUserId,
		fyersId: "",
		Profitloss: "",
	});

	const [detailData, setDetailData] = useState([]);
	const [detailRecord, setDetailRecord] = useState({
		symbol: "",
		symbolContract: "",
		product: "",
		type: "",
		qty: "",
		avgPrice: "",
		avgSellPrice: "",
		detProfitloss: "",
	});

	const handleDateChange = (date, dateString) => {
		debugger
		setTdate(dateString);
		if (!dateString) return;
		setLoading(true);
		Showprofitandlossdata.showprofitandlossdata(storedUserId, 2, dateString)
			.then((apiRes) => {
				setLoading(false);
				if (apiRes.data.data && apiRes.data.data.length > 0) {
					const existingData = apiRes.data.data[0];
					setMasterData({
						TId: existingData.tId,
						TDate: dateString,
						UserId: existingData.userId,
						fyersId: existingData.fyersId,
						Profitloss: existingData.profitloss,
					});
					setDetailData(existingData.det);
					message.success("Existing data loaded!");
				} else {
					setMasterData({
						TId: 0,
						TDate: dateString,
						UserId: storedUserId,
						fyersId: "",
						Profitloss: "",
					});
					setDetailData([]);
					message.info("No existing data found, Ready for new entry.");
				}
			})
			.catch(() => {
				setLoading(false);
				message.error("Failed to fetch data.");
			});
	};

	const handleDetailChange = (key, value) => {
		setDetailRecord({ ...detailRecord, [key]: value });
	};

	const addDetailRecord = () => {
		if (!detailRecord.symbol || !detailRecord.symbolContract || !detailRecord.qty || !detailRecord.avgPrice) {
			message.error("Please fill all required fields in detail section.");
			return;
		}

		const updatedDetailData = [...detailData, detailRecord];
		const totalProfitLoss = updatedDetailData.reduce((sum, record) => sum + (parseFloat(record.detProfitloss) || 0), 0);

		setDetailData(updatedDetailData);
		setMasterData({ ...masterData, Profitloss: totalProfitLoss.toString() });

		setDetailRecord({
			symbol: "",
			symbolContract: "",
			product: "",
			type: "",
			qty: "",
			avgPrice: "",
			avgSellPrice: "",
			detProfitloss: "",
		});
	};

	const handleEdit = (index) => {
		setEditingIndex(index);
		setDetailRecord(detailData[index]); // Load selected row data into form
	};

	const handleDelete = (index) => {
		const updatedDetailData = detailData.filter((_, i) => i !== index);
		const totalProfitLoss = updatedDetailData.reduce((sum, record) => sum + (parseFloat(record.detProfitloss) || 0), 0);

		setDetailData(updatedDetailData);
		setMasterData({ ...masterData, Profitloss: totalProfitLoss.toString() });
		message.success("Record deleted successfully!");
	};

	const updateDetailRecord = () => {
		if (editingIndex === null) return;

		const updatedDetailData = [...detailData];
		updatedDetailData[editingIndex] = detailRecord;

		const totalProfitLoss = updatedDetailData.reduce((sum, record) => sum + (parseFloat(record.detProfitloss) || 0), 0);

		setDetailData(updatedDetailData);
		setMasterData({ ...masterData, Profitloss: totalProfitLoss.toString() });

		setEditingIndex(null);
		setDetailRecord({
			symbol: "",
			symbolContract: "",
			product: "",
			type: "",
			qty: "",
			avgPrice: "",
			avgSellPrice: "",
			detProfitloss: "",
		});

		message.success("Record updated successfully!");
	};

	const handleSubmit = () => {
		if (!masterData.TDate || !masterData.Profitloss) {
			message.error("Please fill all required fields in master section.");
			return;
		}

		const payload = { ...masterData, Det: detailData };

		Postprofitandloss.profitandlossdash(payload)
			.then(() => {
				message.success("Data saved successfully!");
				form.resetFields();
				setDetailData([]);
			})
			.catch(() => {
				message.error("Error: Data not saved!");
			});
	};

	const columns = [
		{ title: "Symbol", dataIndex: "symbol", key: "symbol" },
		{ title: "Contract", dataIndex: "symbolContract", key: "symbolContract" },
		{ title: "Product", dataIndex: "product", key: "product" },
		{ title: "Type", dataIndex: "type", key: "type" },
		{ title: "Quantity", dataIndex: "qty", key: "qty" },
		{ title: "Avg Price", dataIndex: "avgPrice", key: "avgPrice" },
		{ title: "Avg Sell Price", dataIndex: "avgSellPrice", key: "avgSellPrice" },
		{ title: "P&L", dataIndex: "detProfitloss", key: "detProfitloss" },
		{
			title: "Actions",
			key: "actions",
			render: (_, record, index) => (
				<>
					<Button type="link" onClick={() => handleEdit(index)}>Edit</Button>
					<Button type="link" danger onClick={() => handleDelete(index)}>Delete</Button>
				</>
			),
		},
	];

	return (
		<div style={{ padding: "20px", maxWidth: "800px", marginTop: "100px", marginLeft: "25%", background: "#f5f5f5", borderRadius: "10px" }}>
			<Form layout="vertical" form={form}>
				<h5>Master Data {editingIndex}</h5>
				<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5px" }}>
					<Form.Item label="Tid" style={{ visibility: "hidden" }}>
						<Input value={masterData.TId} disabled />
					</Form.Item>
					<Form.Item label="Date">
						<DatePicker style={{ width: "100%" }} onChange={handleDateChange} />
					</Form.Item>
					<Form.Item label="Profit/Loss">
						<Input value={masterData.Profitloss} disabled />
					</Form.Item>
					<Form.Item label="FyersId">
						<Input value={masterData.fyersId}  onChange={(e) => setMasterData({ ...masterData, fyersId: e.target.value })} />
					</Form.Item>
				</div>

				<h5>Detail Data</h5>
				<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5px" }}>
					{Object.keys(detailRecord).map((key) => (
						<Input key={key} placeholder={key} value={detailRecord[key]} onChange={(e) => handleDetailChange(key, e.target.value)} />
					))}
				</div>

				<Button type="primary" className="mt-3 mb-3" onClick={editingIndex !== null ? updateDetailRecord : addDetailRecord}>
					{editingIndex !== null ? "Update Record" : "Add Detail"}
				</Button>

				<Table columns={columns} dataSource={detailData} rowKey={(record, index) => index} />

				<Button type="primary" block onClick={handleSubmit}>Save Data</Button>
			</Form>
		</div>
	);
};

export default ProfitAndLossPostData;
