import https from "../../utility/api-client";

class Cutsomcalenderprofitlosss 
{
    cutsomcalenderprofitlosss (Tradedate,fyersid,userid)
    {
        return https.get('/Fyers/GetMTradeData?TradeDate='+ Tradedate +'&FyersId='+ fyersid+'&UserId='+ userid)
    }
}

export default new Cutsomcalenderprofitlosss();