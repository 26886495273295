import React, { useState, useEffect } from "react";
import "./Dashboard.css"; // Import CSS file
import pldata from "./pldata.json"; // Import JSON data
import Showprofitandlossdata from "../store/services/Showprofitandlossdata";
import { DatePicker } from "antd";
import dayjs from "dayjs";
const DashboardPL = () => {
  debugger
  const [filterDate, setFilterDate] = useState("");
  const [data, setData] = useState([]);

  const handleDateChange = (date, dateString) => {
    debugger
    setFilterDate(dateString); // Store date as YYYY-MM-DD
  }


  useEffect(() => {
    debugger
    // let userid = localStorage.getItem('userid');
    let UserId = localStorage.getItem('userid');
    let FyersId = 2
    let TradeDate = filterDate === "" ? 0 : filterDate;
    Showprofitandlossdata.showprofitandlossdata(UserId, FyersId, TradeDate).then((apiRes) => {
      debugger
      setData(apiRes.data.data)
      console.log(apiRes.data.data)

    })
      .catch((error) => {
        console.log("faild")
        debugger
      });

  }, [filterDate])



  // Load data from JSON
  // useEffect(() => {
  //   setData(pldata);
  // }, []);
  debugger
  // Filter data based on selected date
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-"); // Assuming input is "YYYY-MM-DD"
    return `${day}-${month}-${year}`; // Convert to "DD-MM-YYYY"
  };
  

  const filteredData = filterDate
    ? data.filter((trade) => formatDate(trade.tDate) === filterDate)
    : data;


  // Group data by date
  const groupedData = filteredData.reduce((acc, trade) => {
    debugger
    if (!acc[trade.tDate]) {
      acc[trade.tDate] = [];
    }
    acc[trade.tDate].push(trade);
    return acc;
  }, {});

  return (
    <div className="dashboard-container" style={{marginTop:"100px" }}>
      {filterDate}
      {/* Filters */}
      <div className="filters">
      <label>
        Date:
        <DatePicker
          value={filterDate ? dayjs(filterDate) : null}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          placeholder="YYYY-MM-DD"
          className="custom-datepicker"
        />
      </label>
    </div>

      {/* Grouped Trades */}
      {Object.entries(groupedData).map(([date, trades]) => {
        // ✅ Correct Profit/Loss Calculation
        const totalGroupProfitLoss = trades.reduce(
          (acc, trade) =>
            acc + trade.det.reduce((sum, det) => sum + det.detProfitloss, 0),
          0
        );


        return (
          <div key={date} className="group py-3 mb-2">
            {/* Group Header */}
            <div
              className="group-header mb-3"
              style={{
                padding: "10px",
                border: "1px solid #abc4ff",
                borderRadius: "5px",
                background: "#edf2fb",
              }}
            >
              <h4>
                Date: {date} Total Profit/Loss:{" "}
                <span
                  className={
                    totalGroupProfitLoss === 0
                      ? "neutral"
                      : totalGroupProfitLoss < 0
                        ? "loss"
                        : "profit"
                  }
                >
                  {totalGroupProfitLoss.toFixed(2)}
                </span>
              </h4>
            </div>

            {/* Trade List */}
            {trades.map((trade, index) =>
              trade.det.map((det, detIndex) => (
                <div key={`${index}-${detIndex}`} className="cardTrade">
                  <div className="trade-item">
                    <div className="tradeexp">
                      <h4>
                        {det.symbol} {det.symbolContract}
                      </h4>
                      <p className="sub-text">
                        <span
                          className={`badge ${det.type === "Buy" ? "buy-badge" : "sell-badge"
                            }`}
                        >
                          {det.type === "Buy" ? "B" : "S"}
                        </span>

                        <span className="intraday-badge">{det.product}</span>
                        Qty. {det.qty} x {det.avgPrice} NSE
                      </p>
                    </div>
                    <div className="pnadl">
                      <h4 className={det.detProfitloss < 0 ? "loss" : "profit"}>
                        {det.detProfitloss.toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DashboardPL;
